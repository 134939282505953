.chat-fixed{
    position: fixed;
    top: 180px;
    right: 0;
    height: calc(100vh - 210px);
    width: 40vw;
    /* background-color: var(--color-statusDestructive); */
  }
  
  .chat-interface {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 60px);
    margin: 0 50px 0 10px;
    background-color: var(--color-backgroundNormal);
    z-index: 1000;
  }
  
  .chat-history {
    flex: 1;
    overflow-y: auto;
    margin: 0 0 0 5px;
    /* padding: 20px; */
  }
  
  .chat-message {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 20px;
    max-width: 80%;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .chat-message.ai {
    align-self: flex-start;
    background-color: var(--color-backgroundAlternative);
    color: var(--color-labelNormal);
    margin-left: 0;
  }
  
  .chat-message.human {
    align-self: flex-end;
    background-color: var(--color-mainNormal);
    color: var(--color-labelDisable);
    margin-left: auto;
  }
  
  .chat-message.human span {
    position: relative;
    right: 0;
  }
  
  .ai-logo {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    align-self: flex-start;
  }
  
  .chat-input-form {
    display: flex;
    padding: 0px 5px;
  }
  
  .chat-input-form:focus {
    background-color: red;
  }
  
  .chat-input {
    flex: 1;
    padding: 12px;
    border: 1px solid var(--color-lineNormal);
    background-color: var(--color-backgroundNormal);
    border-radius: 20px;
    font-size: 14px;
    margin-right: 10px;
  }
  
  .chat-input:focus {
    border-color: var(--color-primaryNormal);
    /* background-color: red; */
  }
  
  .chat-submit {
    padding: 12px 20px;
    background-color: #4e7bff;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  
  .chat-submit:hover {
    background-color: #3d62cc;
  }