.subtitle-summary h2 {
    color: var(--color-labelNormal);
    margin: 15px 0 2px 0;
  }
  
  .tab-buttons {
    display: flex;
    margin-bottom: 12px;
  }
  
  .tab-buttons button {
    background: none;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-labelAlternative);
    transition: all 0.3s ease;
  }
  
  .tab-buttons button.active {
    color: var(--color-primaryNormal);
    position: relative;
  }
  
  .tab-buttons button.active::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color-primaryNormal);
  }
  
  .content {
    background-color: var(--color-backgroundAlternative);
    padding: 5px 20px;
    border-radius: 10px;
  }
  
  .content p {
    line-height: 1.6;
    color: var(--color-labelNormal);
    margin: 0;
  }