.App {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--color-backgroundNormal);
}

.content-container {
  /* padding: 0 50px;  양쪽에 25px씩 패딩 적용 */
  display: flex;
  width: 100%;
  /* gap: 10px; */
  height: 100vh;
  /* box-sizing: border-box; */
}

.content-video {
  flex: 6;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--color-lineNormal);
  /* padding: 180px 10px 0 50px; */
  /* margin-top: 180px; */
}

.content-chat {
  flex: 4;
}

.video-subtitle {
  display: flex;
  flex-direction: column;
  margin: 180px 10px 0 50px;
}

.divider {
  position: fixed;
  top: 162px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-lineNormal);
  z-index: 1000;
}