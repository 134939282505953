.main-container {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 100px);
  padding: 10px 50px 15px 50px;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--color-backgroundNormal);
  z-index: 10;
  font-size: 16px;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.logo {
  height: 30px;
  margin-right: 15px;
}

.search-form {
  display: flex;
  flex-grow: 1;
  max-width: 600px;
  box-shadow: 0 1px 6px var(--color-labelAssistive);
  border-radius: 24px;
  overflow: hidden;
}

.search-input {
  flex-grow: 1;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  outline: none;
}

.search-button {
  padding: 0 20px 0 15px;
  font-size: 16px;
  background-color: var(--color-interactionDisable);
  color: var(--color-labelNormal);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-button:hover{
  background-color: var(--color-interactionInactive);
  color: var(--color-labelDisable);
}

.section-label {
  color: var(--color-labelNormal);
  font-weight: bolder;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.example-queries {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.example-button {
  margin-right: 10px;
  padding: 8px 16px;
  background-color: var(--color-backgroundAlternative);
  color: var(--color-labelNormal);
  border: 1px solid var(--color-primaryNormal);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.example-button:hover {
  background-color: var(--color-primaryNormal);
  color: var(--color-labelDisable);
  border: 1px solid var(--color-primaryNormal);
  box-shadow: 0 1px 1px var(--color-labelDisable);
}

.example-lectures-container {
  width: 100%;
  overflow: hidden;
  margin: 0 0 15px 0;
  display: flex;
  align-items: center;
}

.example-lectures {
  display: inline-flex;
  white-space: nowrap;
  transition: transform 0.5s ease;
  margin-left: 5px;
}

.example-lectures.scrolling {
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% + 100vw));
  }
}

.lecture-item {
  margin-right: 10px;
  padding: 2px 10px 4px 10px;
  color: var(--color-labelDisable);
  font-size: 14px;
  background-color: var(--color-mainNormal);
  border-radius: 4px;
}